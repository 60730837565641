import React from "react";
import des_01 from "../assets/design.png";

const End = () => {
  return (
    <div className="pattern center space-top">
      <span className="line" />
      <img src={des_01} alt="" style={{ height: "4vh" }} />
      <span className="line" />
    </div>
  );
};

export default End;
