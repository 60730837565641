import React from "react";
import sneha from "../assets/sneha.jpeg";
import anmol from "../assets/anmol.jpg";
import { FaLinkedin, FaTwitter, FaGlobe, FaItchIo } from "react-icons/fa";
import End from "./End";

const Team = () => {
  return (
    <>
      <div className="info">
        <div className="title">
          <h2 className="flicker">Team</h2>
        </div>
        <div className="space-top">
          <div className="team-grid center space-sides">
            <div className="">
              <div className="single-box">
                <div className="stuff-image ts">
                  <img src={anmol} alt="" />
                </div>
                <div className="details p-3">
                  <span className="uppercase lighter">Anmol Kaul</span>

                  <div className="grid center mt-5">
                    <a
                      href="https://www.linkedin.com/in/anmolkaul/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin size={20} className="no-decor white ts" />
                    </a>
                    <a
                      href="https://twitter.com/etothepowerth"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter size={20} className="no-decor white ts" />
                    </a>
                    <a
                      href="https://anmolkaul.weebly.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaGlobe size={20} className="no-decor white ts" />
                    </a>
                    <a
                      href="https://anmolkaul.itch.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaItchIo size={20} className="no-decor white ts" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="single-box">
                <div className="stuff-image ts">
                  <img src={sneha} alt="" />
                </div>
                <div className="details">
                  <span className="uppercase lighter">Sneha Agarwalla</span>

                  <div className="grid center mt-5">
                    <a
                      href="https://www.linkedin.com/in/sneha-agarwalla/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin size={20} className="no-decor white ts" />
                    </a>
                    <a
                      href="https://twitter.com/lla_dusk"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter size={20} className="no-decor white ts" />
                    </a>
                    <a
                      href="https://snag00.wixsite.com/sneha-agarwalla"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaGlobe size={20} className="no-decor white ts" />
                    </a>
                    <a
                      href="https://ethosgames.itch.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaItchIo size={20} className="no-decor white ts" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <End />
    </>
  );
};

export default Team;
