import Router from "./routes";
import "./App.css";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import { useReducer } from "react";
import { useEffect } from "react";

function App() {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    forceUpdate();
  }, []);
  return (
    <>
      <Navbar />
      <Router />
    </>
  );
}

export default App;
