import React, { useState } from "react";
import jsonp from "../jsonp";
import End from "./End";
import ReactLoading from "react-loading";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [contextMsg, setContextMsg] = useState("");
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setEmail("");
    setContextMsg("We shall keep you in our thoughts!");

    const url = process.env.REACT_APP_MAILCHIMP_ACTION_URL;

    try {
      jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, data) => {
        const { msg, result } = data;
        // alert(msg);
      });
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="info">
        <div className="title">
          <h2 className="flicker">Let's Be Friends</h2>
        </div>
        <div className="space-top">
          <form onSubmit={onSubmit}>
            <div>
              <span className="letter-space highlight extra-small">
                {contextMsg}
              </span>
              <br />
              <input
                type="email"
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="input white letter-space"
                placeholder="Email Address"
              ></input>
              <br />

              <button
                className="btn-normal letter-space"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && (
                  <ReactLoading type={"bubbles"} height={30} width={30} />
                )}
                {!isLoading && <span>Submit</span>}
              </button>
              {console.log(isLoading)}
            </div>
          </form>
        </div>
      </div>
      <End />
    </>
  );
};

export default Subscribe;
