import { Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";
import LoadingScreen from "../pages/LoadingScreen";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Games = Loadable(lazy(() => import("../pages/Games")));
const Game = Loadable(lazy(() => import("../pages/Game")));
const Mansaar = Loadable(lazy(() => import("../pages/Mansaar")));
const Nowhere = Loadable(lazy(() => import("../pages/Nowhere")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

const Router = () => {
  return useRoutes([
    {
      path: "/",
      element: <About />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/games",
      element: <Games />,
    },
    {
      path: "/games/:name",
      element: <Game />,
    },
    {
      path: "/Mansaar",
      element: <Mansaar />,
    },
    {
      path: "/Nowhere",
      element: <Nowhere />,
    },
    { path: "*", element: <NotFound /> },
  ]);
};

export default Router;
