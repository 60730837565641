const LoadingScreen = () => {
  return (
    <div className="loader-container">
      <div>
        <div className="spinner"></div>
        {/* <span className="grey lighter"></span> */}
      </div>
    </div>
  );
};

export default LoadingScreen;
