import React from "react";

const BGVideo = ({ bgVideo }) => {
  return (
    <>
      <video key={bgVideo} muted loop={true} autoPlay className="video">
        <source src={bgVideo} type="video/mp4" />
      </video>
    </>
  );
};

export default BGVideo;
